<template>
  <div>
    <h2>{{ !$route.query.code?'暂无code':'正在登陆' }}</h2>
    <!-- <h1>authCallback</h1> -->
    <!-- <button @click="goto">下一页</button> -->
  </div>
</template>

<script>
export default {
  created() {
    this.goto()
  },
  methods: {
    goto() {
      console.log('authCallback页', this.$route)
      // this.$message(this.$route.query.code)
      // if (this.$route.query.auth_code) localStorage.setItem('auth_code', JSON.stringify(this.$route.query.auth_code))
      // this.$router.push('/dashboard')
     if(this.$route.query.code){
      // window.location.href = `https://pmtapi3.uelink.com.cn/pmtapi3/yongWecom/loginCallback?auth_code=${this.$route.query.code}`
     }
    }
  }
}
</script>

<style></style>
